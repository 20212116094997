* {
  box-sizing: border-box;
  border: 0;
  padding: 0;
}
.info {
  position: relative;
  display: flex;
  z-index: 2;
}

.info-icon {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0.8rem;
  z-index: 3;
  font-size: 1.3rem;
  margin-left: 0.3rem;
  color: black;
}
.prev {
  width: 14.5rem;
  height: 3rem;
  position: absolute;
  background-color: white;
  z-index: 3;
  padding: 0.6rem;
  margin-top: -5rem;
  border-radius: 0.6rem;
  transition: 0.4s ease;
  display: flex;
  align-items: center;
}
.prev div {
  padding: 0.3rem;
  width: 3rem;
  height: 2rem;
  margin-left: 0.7rem;
  border-radius: 0.6rem;
  background-color: black;
  text-align: center;
  color: white;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.prev div:hover {
  background-color: hsl(0, 0%, 26%);
}
.history {
  margin-left: 0.5rem;
  font-size: 2rem;
  color: purple;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.history-gray {
  margin-left: 0.5rem;
  font-size: 2rem;
  color: black;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.guide {
  left: 0;
  position: absolute;
  font-size: 1.1rem;
  margin-left: 0.6rem;
  color: hsl(60, 100%, 85%);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.guide a {
  color: hsl(60, 100%, 85%);
}
.guide-gray {
  left: 0;
  position: absolute;
  font-size: 1.1rem;
  margin-left: 0.6rem;
  color: white;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.guide-gray a {
  color: hsl(60, 100%, 85%);
}
.guide50 {
  left: 0;
  position: absolute;
  font-size: 1.1rem;
  margin-left: 0.6rem;
  color: hsl(60, 100%, 85%);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.guide50 a {
  color: white;
}
.guide100 {
  left: 0;
  position: absolute;
  font-size: 1.1rem;
  margin-left: 0.6rem;
  color: purple;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.guide100 a {
  color: purple;
}
.param {
  margin-top: 0.8rem;
  position: relative;
  z-index: 2;
  color: hsl(60, 100%, 85%);
  text-align: center;
  font-weight: 900;
  font-size: 1.2rem;
}
.param-gray {
  margin-top: 0.8rem;
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  font-weight: 900;
  font-size: 1.2rem;
}
.param100 {
  margin-top: 0.8rem;
  position: relative;
  z-index: 2;
  color: purple;
  text-align: center;
  font-weight: 900;
  font-size: 1.2rem;
}
.para {
  position: relative;
  z-index: 2;
  color: hsl(60, 100%, 85%);
  text-align: center;
  font-weight: 200;
  font-size: 1rem;
  margin-top: -0.1rem;
}
.para-gray {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  font-weight: 200;
  font-size: 1rem;
  margin-top: -0.1rem;
}
.para100 {
  position: relative;
  z-index: 2;
  color: black;
  text-align: center;
  font-weight: 200;
  font-size: 1rem;
  margin-top: -0.1rem;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-dark {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
}
.container-dark50 {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: hsl(0, 0%, 69%);
}
.container-dark100 {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.container-grayscale {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-color: black;
}
nav {
  width: 100vw;
  height: 2rem;
  display: flex;
  position: relative;
  z-index: 4;
  background-color: transparent;
  color: rgb(255, 255, 176);
}
.intensity-option {
  width: 10rem;
  height: 6rem;
  background-color: hsl(60, 100%, 85%);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option li {
  color: purple;
  font-size: 0.8rem;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option .percent {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option .percent p {
  padding: 0.4rem;
  width: 3.9rem;
  height: 2rem;
  text-align: center;
  margin-bottom: -0.1rem;
  margin-top: -0.2rem;
  margin-right: 0.2rem;
  background: linear-gradient(145deg, #ffffc0, #e6e6a1);
  box-shadow: 3px 3px 2px #99996b, -5px -5px 10px #ffffc0;
  border-radius: 0.9rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option100 {
  width: 10rem;
  height: 6rem;
  background-color: hsl(60, 100%, 85%);
  box-shadow: 3px 3px 2px gray;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

.intensity-option100 li {
  color: purple;
  font-size: 0.8rem;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option100 .percent {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option-gray {
  width: 10rem;
  height: 6rem;
  background-color: white;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option-gray li {
  color: black;
  font-size: 0.8rem;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option-gray .percent {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.intensity-option-gray .percent p {
  padding: 0.4rem;
  width: 3.9rem;
  height: 2rem;
  text-align: center;
  margin-bottom: -0.1rem;
  margin-top: -0.2rem;
  margin-right: 0.2rem;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 3px 3px 2px #b8b8b8, -3px -3px 2px #ffffff;
  border-radius: 0.9rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.active {
  color: gray;
  font-weight: 800;
  /* text-align: center; */
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 6;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option .percentage {
  display: flex;
  cursor: pointer;
  margin-top: 0.6rem;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  background: linear-gradient(145deg, #ffffc0, #e6e6a1);
  box-shadow: 3px 3px 2px #99996b, -5px -5px 10px #ffffc0;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option-gray .percentage {
  display: flex;
  cursor: pointer;
  margin-top: 0.6rem;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 3px 3px 2px #b8b8b8, -7px -7px 14px #ffffff;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option100 .percentage {
  display: flex;
  cursor: pointer;
  margin-top: 0.6rem;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  background: linear-gradient(145deg, #ffffc0, #e6e6a1);
  box-shadow: 3px 3px 2px #99996b, -5px -5px 10px #ffffc0;
  -webkit-tap-highlight-color: transparent;
}
.intensity-option100 .percent p {
  padding: 0.4rem;
  width: 3.9rem;
  height: 2rem;
  text-align: center;
  margin-bottom: -0.1rem;
  margin-top: -0.2rem;
  margin-right: 0.2rem;
  background: linear-gradient(145deg, #ffffc0, #e6e6a1);
  box-shadow: 3px 3px 2px #99996b, -5px -5px 10px #ffffc0;
  border-radius: 0.9rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.percentage li {
  padding-left: 0.5rem;
}
.eye-icon {
  background-color: transparent;
  /* margin: 0 0.5rem 0 0.5rem; */
  margin-top: -0.6rem;
  padding-top: 0.7rem;
  font-size: 2.4rem;
}
.percentage li {
  font-size: 1.2rem;
  height: 2rem;
  -webkit-tap-highlight-color: transparent;
}
nav li {
  list-style: none;
  background-color: transparent;
}
nav ul {
  margin-left: auto;
  font-size: 2rem;
  display: flex;
}
.dark-icon {
  background-color: transparent;
}
.dark-icon-gray {
  background-color: transparent;
  color: white;
}
.dark-icon100 {
  background-color: transparent;
  color: purple;
}
.blur {
  background: rgba(255, 255, 255, 0);
  /* box-shadow: 3px 3px 2px gba(0, 0, 0, 0.1); */
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(5.5px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.close-window {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
}
.close-window-info {
  background: rgba(0, 0, 0, 0.486);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.window-info {
  background-color: white;
  width: 20rem;
  height: 15rem;
  border-radius: 1rem;
  position: fixed;
  z-index: 3;
  padding: 1rem;
  text-align: center;
}
.close-window2 {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.img {
  z-index: 0;
  margin-top: -4rem;
  position: absolute;
  top: 0;
  left: 33vw;
}
.input {
  background-color: transparent;
  position: relative;
  z-index: 2;
  width: 25rem;
  height: 3rem;
  border-radius: 2rem;
  padding-left: 0.7rem;
  border: none;
  font-size: 1.3rem;
}
.input:focus {
  border: none;
  outline: none;
}
.inpu {
  z-index: 2;
  position: absolute;
  background-color: rgb(255, 255, 176);
  width: 1.5rem;
  height: 1.5rem;
  top: 0;
  right: 0;
}
.checks {
  width: 10rem;
  height: 2rem;
  font-size: 0.8rem;
  padding: 10px;
  outline: none;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: rgb(255, 255, 176);
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
}
.checks .info-icon {
  margin-top: 0.3rem;
}
.checks label {
  margin-left: 1.2rem;
}
.checks-gray {
  width: 10rem;
  height: 2rem;
  font-size: 0.8rem;
  padding: 10px;
  outline: none;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: white;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
}
.checks-gray .info-icon {
  margin-top: 0.3rem;
  color: black;
}
.checks-gray label {
  margin-left: 1.2rem;
  color: black;
}
.par {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
}
.title {
  display: flex;
  margin-left: 1rem;
  margin-bottom: -3rem;
}
.sub-container {
  z-index: 2;
  display: flex;
  align-items: center;
  position: relative;
  background-color: rgb(255, 255, 176);
  margin-top: -1rem;
  width: 30rem;
  height: 3rem;
  border-radius: 2rem;
}
.sub-container-gray {
  z-index: 2;
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  margin-top: -1rem;
  width: 30rem;
  height: 3rem;
  border-radius: 2rem;
}
.sub-container-gray .search {
  background-color: transparent;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  color: black;
  font-size: 2rem;
  cursor: pointer;
  margin-right: 0;
}
.search {
  background-color: transparent;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  color: purple;
  font-size: 2rem;
  cursor: pointer;
  margin-right: 0;
}
.motion-gray {
  color: white;
  font-size: 4rem;
  z-index: 2;
  position: relative;
  background-color: transparent;
  margin-top: 0rem;
}
.motion-gray::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: white;
  -webkit-text-stroke: 0.1rem rgba(0, 0, 0, 0.507);
  overflow: hidden;
  animation: animate 4s linear;
}
.motion {
  color: purple;
  font-size: 4rem;
  z-index: 2;
  position: relative;
  background-color: transparent;
  margin-top: 0rem;
}
.motion::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: black;
  -webkit-text-stroke: 0.1rem rgba(255, 255, 176, 0.507);
  overflow: hidden;
  animation: animate 4s linear;
}

@keyframes animate {
  0% {
    width: 0;
  }
  90% {
    width: 100%;
  }
  /* 100% {
    width: 0%;
  } */
}
.footer {
  font-size: 0.8rem;
  position: absolute;
  z-index: 2;
  color: hsl(60, 100%, 85%);
  bottom: 0;
  width: 100%;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}
.footer a {
  margin-left: 2rem;
  color: hsl(60, 100%, 85%);
  text-decoration: none;
}
.footer-gray a {
  margin-left: 2rem;
  color: white;
  text-decoration: none;
}
.footer100 a {
  margin-left: 2rem;
  color: purple;
  text-decoration: none;
}
.footer-gray {
  font-size: 0.8rem;
  position: absolute;
  z-index: 2;
  color: white;
  bottom: 0;
  width: 100%;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}
.footer100 {
  font-size: 0.8rem;
  position: absolute;
  z-index: 2;
  color: purple;
  bottom: 0;
  width: 100%;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}
@media screen and (max-width: 767px) {
  .img {
    width: 250px;
    height: 250px;
    margin-top: 1.6rem;
    margin-left: -3rem;
  }
  .motion {
    font-size: 2.5rem;
  }
  .motion-gray {
    font-size: 2.5rem;
  }
  .input {
    width: 15rem;
  }
  .sub-container {
    width: 20rem;
    margin-top: 0;
    margin-left: 0.3rem;
  }
  .sub-container-gray {
    width: 20rem;
    margin-top: 0;
    margin-left: 0.3rem;
  }
  .search {
    margin-top: 0.2rem;
  }
  .motion {
    margin-top: 3rem;
  }
  .motion-gray {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 991px) and (min-width: 767px) {
  .img {
    margin-top: 1.6rem;
    margin-left: -5rem;
  }
}
