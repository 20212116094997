/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap"); */

.form-input {
  position: relative;
  width: 100%;
  z-index: 0;
  margin: 0.5rem 0 0.5rem 0;
  z-index: 2;
}

.form-input input::placeholder {
  opacity: 0;
}

.form-input input {
  width: 10rem;
  height: 2rem;
  font-size: 0.8rem;
  padding: 10px 10px 10px 30px;
  outline: none;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 5px;
}
.gray input {
  background-color: white;
}
.dark input {
  background-color: rgb(255, 255, 176);
}
.form-input label {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8rem;
  color: black;
  letter-spacing: 1px;
  transition: 0.1s;
  font-weight: 400;
}

.dark input:focus + label,
.form-input input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 0.6rem;
  color: black;
  background: rgb(255, 255, 176);
  padding: 3px;
  border-radius: 8px;
}

.gray input:focus + label,
.form-input input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 0.6rem;
  color: black;
  background: white;
  padding: 3px;
  border-radius: 8px;
}

.form-input input:focus {
  outline: 1.2px solid hsl(39, 77%, 36%);
}
/* @media screen and (max-width: 767px) {
.form-input input{
  width: ;
}
} */
